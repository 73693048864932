import { RouterProvider } from "react-router-dom";
// import { ThemeProvider } from "@/components/theme-provider"
import { router } from "./Router";
import ReactGA from 'react-ga4';
import React, { useEffect } from 'react';

export default function App() {
    useEffect(() => {
        ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS_ID);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    return (
        // <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
        <RouterProvider router={router} />
        // </ThemeProvider>
    )
}
