// import { createBrowserRouter } from "react-router-dom";

// // import { Applayout } from "./components/layouts/AppLayout";

// import NoMatch from "./pages/NoMatch";
// import Dashboard from "./pages/Dashboard";
// import Empty from "./pages/Empty";
// import Sample from "./pages/Sample";

// export const router = createBrowserRouter([
//     {
//         path: "/",
//         // element: <Applayout />,
//         children: [
//             {
//                 path: "",
//                 element: <Dashboard />,
//             },
//             {
//                 path: "sample",
//                 element: <Sample />,
//             },
//             {
//                 path: "empty",
//                 element: <Empty />,
//             },
//         ],
//     },
//     {
//         path: "*",
//         element: <NoMatch />,
//     },
// ], {
//     basename: global.basename
// })

import { createBrowserRouter } from "react-router-dom";

import NoMatch from "./pages/NoMatch";
import Dashboard from "./pages/Dashboard";
import Empty from "./pages/Empty";
import Sample from "./pages/Sample";

export const router = createBrowserRouter([
    {
        path: "/",
        children: [
            {
                path: "",
                element: <Dashboard />,
            },
            {
                path: "sample",
                element: <Sample />,
            },
            {
                path: "empty",
                element: <Empty />,
            },
        ],
    },
    {
        path: "*",
        element: <NoMatch />,
    },
]);