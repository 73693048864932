import { useEffect, useState, useRef, useCallback } from "react"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Select } from "@/components/ui/select"
import { AlertCircle, Search, Bookmark, BookmarkCheck, Sparkles, Paperclip, Upload, Accessibility, Gitlab, Ghost, Github, Linkedin, Omega, X, FileText, ChevronDown, ChevronUp, Plus, Minus } from "lucide-react"
import api from "@/utils/api"
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import React from "react"
import ReactGA from 'react-ga4';
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import ReactMarkdown from 'react-markdown'
import { motion, AnimatePresence } from "framer-motion"
import { Check, ChevronsUpDown } from "lucide-react"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { useDropzone } from 'react-dropzone';

interface Job {
  company: string
  title: string
  location: string
  tags: string[]
  url: string
  neural_summary: string
  id: string
}

const cn = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ')
}

export default function Dashboard() {
  const [jobs, setJobs] = useState<Job[]>([])
  const [loading, setLoading] = useState(false)
  const [iconIndex, setIconIndex] = useState(0)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [searchText, setSearchText] = useState("")
  const [jobCount, setJobCount] = useState<number | null>(null)
  const [showHint, setShowHint] = useState(false)
  const [resumeFile, setResumeFile] = useState<File | null>(null)
  const [isSearchTriggered, setIsSearchTriggered] = useState(false)
  const [showFormatAlert, setShowFormatAlert] = useState(false)
  const [expandedJobId, setExpandedJobId] = useState<number | null>(null)
  const [attachedJobs, setAttachedJobs] = useState<Job[]>([])
  const [selectedLocations, setSelectedLocations] = useState<string[]>([])
  const [locationOpen, setLocationOpen] = useState(false)
  const [locations, setLocations] = useState<{ value: string; label: string }[]>([])
  const [locationFilter, setLocationFilter] = useState("")

  const icons = [Accessibility, Gitlab, Ghost, Github, Linkedin, Omega]

  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited')
    if (!hasVisited) {
      setShowHint(true)
      localStorage.setItem('hasVisited', 'true')
    }

    const fetchJobs = async () => {
      try {
        const response = await api.get('/jobs')
        setJobs(response.data)
      } catch (error) {
        console.error("Error fetching jobs:", error)
      }
    }

    const fetchJobCount = async () => {
      try {
        const response = await api.get('/jobs/count')
        setJobCount(response.data.job_count)
      } catch (error) {
        console.error("Error fetching job count:", error)
      }
    }

    fetchJobs()
    fetchJobCount()
  }, [])

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/', title: 'Dashboard' });
  }, []);

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setIconIndex((prevIndex) => (prevIndex + 1) % icons.length)
      }, 500)
      return () => clearInterval(interval)
    }
  }, [loading, icons.length])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleSearch()
      }
    }

    const inputElement = document.getElementById('search-input')
    inputElement?.addEventListener('keydown', handleKeyDown)

    return () => {
      inputElement?.removeEventListener('keydown', handleKeyDown)
    }
  }, [searchText])

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement> | { target: { files: File[] } }) => {
    const file = event.target.files?.[0]
    if (file) {
      if (file.type !== 'application/pdf') {
        setShowFormatAlert(true)
        if (fileInputRef.current) {
          fileInputRef.current.value = ''
        }
        return
      }

      setIsSearchTriggered(true)
      setShowFormatAlert(false)
      setResumeFile(file)
      setLoading(true)
      const formData = new FormData()
      formData.append('file', file)
      formData.append('query', searchText)

      try {
        const response = await api.post('/jobs/match', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        setJobs(response.data)
      } catch (error) {
        console.error("Error matching jobs:", error)
      } finally {
        setLoading(false)
      }
    }
  }

  const toggleJobAttachment = (job: Job) => {
    setAttachedJobs(prev => {
      const isAttached = prev.some(j => j.id === job.id)
      if (isAttached) {
        return prev.filter(j => j.id !== job.id)
      } else {
        return [...prev, job]
      }
    })
  }

  const handleSearch = async () => {
    if (searchText.trim() === "" && !resumeFile && attachedJobs.length === 0) return

    ReactGA.event({
      category: 'User',
      action: 'Search',
      label: 'Triggered'
    });

    setIsSearchTriggered(true)
    setLoading(true)

    let response
    const formData = new FormData()
    if (resumeFile) {
      formData.append('file', resumeFile)
    }
    formData.append('query', searchText)
    attachedJobs.length > 0 && formData.append('attached_jobs', JSON.stringify(attachedJobs.map(job => job.id)))
    selectedLocations.length > 0 && formData.append('locations', JSON.stringify(selectedLocations))

    try {
      ReactGA.event({
        category: 'User',
        action: 'Advanced Search',
        label: 'Triggered'
      });

      response = await api.post('/jobs/match', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      setJobs(response.data)
    } catch (error) {
      console.error("Error searching jobs:", error)
    } finally {
      setLoading(false)
    }
  }

  const handleRemoveResume = async () => {
    setResumeFile(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
    const formData = new FormData()
    formData.append('query', searchText)
    const response = await api.post('/jobs/match', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    setJobs(response.data)
  }

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (showFormatAlert) {
      timer = setTimeout(() => {
        setShowFormatAlert(false)
      }, 2000)
    }
    return () => clearTimeout(timer)
  }, [showFormatAlert])

  const toggleJobExpansion = (index: number) => {
    setExpandedJobId(expandedJobId === index ? null : index)
  }

  const handleLocationSelect = (location: string) => {
    setSelectedLocations((prev) =>
      prev.includes(location)
        ? prev.filter((loc) => loc !== location)
        : [...prev, location]
    )
  }

  useEffect(() => {
    if (isSearchTriggered) {
      handleSearch();
    }
  }, [selectedLocations]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await api.get('/jobs/locations')
        const locationData = response.data.map((location: string) => ({
          value: location,
          label: location
        }))
        setLocations(locationData)
      } catch (error) {
        console.error("Error fetching locations:", error)
      }
    }

    fetchLocations()
  }, [])

  const handleLocationDropdownOpenChange = (open: boolean) => {
    setLocationOpen(open);
    if (!open) {
      setLocationFilter("");
    }
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const customEvent = {
        target: { files: acceptedFiles },
      } as unknown as React.ChangeEvent<HTMLInputElement>;
      handleFileUpload(customEvent);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: { 'application/pdf': ['.pdf'] } });

  return (
    <div className="flex flex-col min-h-screen bg-black text-white dark:bg-black dark:text-white light:bg-white light:text-black">
      <header className="px-4 lg:px-6 h-14 flex items-center justify-between">
        <a className="flex items-center justify-center" href="#">
          <span className="text-xl font-semibold">stepwe</span>
        </a>
      </header>
      <main className="flex-1 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto space-y-8">
          <div className={`${!isSearchTriggered && 'flex flex-col justify-center h-[calc(80vh-3.5rem)]'}`}>
            <div className="text-center space-y-4">
              <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold tracking-tight">
                FIND THE BEST JOBS WITH AI
              </h1>
              <p className="text-xl text-gray-400">
                Find the most accurate match in the top company
              </p>
              <p className="text-sm text-gray-500 italic">
                "Searching for a job should not be a job itself." — chatgpt o1-preview ✨
              </p>
            </div>
            <div className="text-center">
              <p className="text-sm mb-2 mt-8">
                ❤️ If you like stepwe, give us a{" "}
                <a href="#" className="underline">
                  shoutout on X
                </a>
              </p>
              <div className="flex flex-col items-center gap-4">
                <div className="relative flex items-center gap-2 w-full max-w-3xl">
                  <div className="flex-grow flex flex-col bg-[#1c1c1c] border border-[#333] rounded-md overflow-hidden transition-all duration-300 ease-in-out p-2">
                    <div className={`flex flex-wrap gap-2 ${(resumeFile || attachedJobs.length > 0) ? 'mb-2' : ''}`}>
                      {resumeFile && (
                        <div className="flex items-center gap-2 bg-[#2a2a2a] rounded-md px-2 py-1 self-start">
                          <FileText className="h-4 w-4 text-blue-400" />
                          <span className="text-xs text-gray-300 truncate max-w-[100px]">{resumeFile.name}</span>
                          <button onClick={handleRemoveResume} className="text-gray-400 hover:text-gray-200">
                            <X className="h-3 w-3" />
                          </button>
                        </div>
                      )}
                      {attachedJobs.map((job) => (
                        <div key={job.id} className="flex items-center gap-2 bg-[#2a2a2a] rounded-md px-2 py-1 self-start">
                          <Paperclip className="h-4 w-4 text-green-400" />
                          <span className="text-xs text-gray-300 truncate max-w-[100px]">{job.title}</span>
                          <button onClick={() => toggleJobAttachment(job)} className="text-gray-400 hover:text-gray-200">
                            <X className="h-3 w-3" />
                          </button>
                        </div>
                      ))}
                    </div>
                    <div className="flex items-center">
                      <button
                        className="p-2 text-gray-400 hover:text-white"
                        onClick={() => fileInputRef.current?.click()}
                      >
                        <Paperclip className="h-5 w-5" />
                      </button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        className="hidden"
                        onChange={handleFileUpload}
                      />
                      <Input
                        id="search-input"
                        className="flex-grow bg-transparent border-none text-white focus:ring-0 focus:outline-none"
                        placeholder="Paste your resume or job description"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        autoComplete="off"
                      />
                      <button
                        className="p-2 text-gray-400 hover:text-white"
                        onClick={handleSearch}
                      >
                        <Search className="h-5 w-5" />
                      </button>
                    </div>
                  </div>
                </div>
                {!isSearchTriggered && (
                  <div className="flex items-center w-full max-w-3xl my-4">
                    <div className="flex-grow border-t border-gray-600"></div>
                    <span className="mx-4 text-xl font-bold text-gray-400">OR</span>
                    <div className="flex-grow border-t border-gray-600"></div>
                  </div>
                )}
                {!isSearchTriggered && (
                  <div className="w-full max-w-3xl">
                    <div
                      {...getRootProps()}
                      className={`neon-border rounded-lg p-20 text-center cursor-pointer transition-colors ${
                        isDragActive ? 'border-gray-800' : ''
                      }`}
                    >
                      <input {...getInputProps()} />
                      <Upload className="mx-auto h-12 w-12 text-gray-400" />
                      <p className="mt-2 text-lg font-semibold text-gray-700">
                        Upload your resume and let our AI find your perfect job!
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        Drag & drop your resume here, or click to select a file
                      </p>
                      <p className="mt-1 text-xs text-gray-400">
                        Only PDF files are accepted
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {showFormatAlert && (
              <Alert className="mt-2">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>
                  Please upload a PDF file. Other formats are not supported yet.
                </AlertDescription>
              </Alert>
            )}
          </div>
          {isSearchTriggered && (
            <div className="space-y-4">
              <div className="flex items-center justify-between text-sm text-gray-400">
                {/* <div className="flex items-center gap-4">
                  <Popover open={locationOpen} onOpenChange={handleLocationDropdownOpenChange}>
                    <PopoverTrigger asChild>
                      <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={locationOpen}
                        className="w-[22.5rem] justify-between"
                      >
                        {selectedLocations.length > 0
                          ? selectedLocations.slice(0, 1).map((loc) => locations.find((l) => l.value === loc)?.label).join(", ") +
                            (selectedLocations.length > 1 ? ` (+${selectedLocations.length - 1} more)` : "")
                          : "Select locations..."}
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-[22.5rem] p-0">
                      <Command>
                        <CommandInput 
                          placeholder="Search locations..." 
                          value={locationFilter}
                          onValueChange={setLocationFilter}
                        />
                        <CommandList>
                          <CommandEmpty>No location found.</CommandEmpty>
                          <CommandGroup>
                            {locations
                              .filter(location => 
                                location.label.toLowerCase().includes(locationFilter.toLowerCase())
                              )
                              .map((location) => (
                                <CommandItem
                                  key={location.value}
                                  value={location.value}
                                  onSelect={() => handleLocationSelect(location.value)}
                                >
                                  <Check
                                    className={cn(
                                      "mr-2 h-4 w-4",
                                      selectedLocations.includes(location.value) ? "opacity-100" : "opacity-0"
                                    )}
                                  />
                                  {location.label}
                                </CommandItem>
                              ))}
                          </CommandGroup>
                        </CommandList>
                      </Command>
                    </PopoverContent>
                  </Popover>
                </div> */}
                {jobCount !== null && (
                  <p className="text-sm text-gray-400">
                    {jobCount.toLocaleString()} jobs found
                  </p>
                )}
              </div>
              <div className="space-y-4">
                {loading ? (
                  <div className="flex items-center justify-center space-x-2 h-full" style={{ marginTop: '2rem' }}>
                    <span>Loading...</span>
                    {React.createElement(icons[iconIndex], { className: "text-white" })}
                  </div>
                ) : (
                  jobs && jobs.length > 0 ? (
                    jobs.map((job, index) => (
                      <motion.div
                        key={job.id}
                        className="relative bg-[#1c1c1c] border border-[#333] rounded-lg p-4"
                        initial={false}
                        animate={{ height: expandedJobId === index ? "auto" : "auto" }}
                        transition={{ duration: 0.1 }}
                      >
                        <div className="flex justify-between items-start">
                          <div className="flex-grow cursor-pointer" onClick={() => toggleJobExpansion(index)}>
                            <div className="text-sm text-gray-500 mb-1">{job.company}</div>
                            <h3 className="text-lg font-semibold mb-1">{job.title}</h3>
                            <div className="text-sm text-gray-400 mb-2">{job.location}</div>
                          </div>
                          <div className="flex items-center justify-end mt-10 mb-8">
                            <Button className="mr-10" onClick={(e) => { window.open(job.url, '_blank'); }}>
                              Apply
                            </Button>
                          </div>

                          <div
                            className="absolute top-3 right-3 cursor-pointer"
                            onClick={(e) => { e.stopPropagation(); toggleJobAttachment(job); }}
                          >
                            {attachedJobs.some(j => j.id === job.id) ? (
                              <BookmarkCheck className="h-5 w-5" />
                            ) : (
                              <Bookmark className="h-5 w-5" />
                            )}
                          </div>
                        </div>
                        
                        <div className="absolute bottom-3 right-3">
                          {expandedJobId === index ? (
                            <ChevronUp className="h-5 w-5 text-gray-400" />
                          ) : (
                            <ChevronDown className="h-5 w-5 text-gray-400" />
                          )}
                        </div>
                        <AnimatePresence>
                          {expandedJobId === index && (
                            <motion.div
                              initial={{ opacity: 0, height: 0 }}
                              animate={{ opacity: 1, height: "auto" }}
                              exit={{ opacity: 0, height: 0 }}
                              transition={{ duration: 0.1 }}
                              className="mt-4 border-t border-[#333] pt-4"
                            >
                              <ReactMarkdown className="prose prose-invert max-w-none">
                                {job.neural_summary}
                              </ReactMarkdown>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </motion.div>
                    ))
                  ) : (
                    <div className="flex items-center justify-center space-x-2 h-full" style={{ marginTop: '2rem' }}>
                      <p>No jobs available</p>
                    </div>
                  )
                )}
              </div>
            </div>
          )}
        </div>
      </main>
      {/* <Dialog open={showHint} onOpenChange={setShowHint}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Tip: Upload Your Resume</DialogTitle>
            <DialogDescription>
              For the most suitable job matches, we recommend uploading your resume. This helps us tailor the job listings to your skills and experience.
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-end">
            <Button onClick={() => setShowHint(false)}>Got it</Button>
          </div>
        </DialogContent>
      </Dialog> */}
    </div>
  )
}